import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Button,
    Chip,
    CircularProgress,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import { format } from 'date-fns';
import CampaignStatistics from './CampaignStatistics';
import { formatNumber } from '../utils/formatters';
import { useAuth } from '../context/AuthContext';
import { useParams } from 'react-router-dom';

const CampaignDetail = () => {
    const [campaign, setCampaign] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [statistics, setStatistics] = useState(null);
    const { token } = useAuth();
    const { id: campaignId } = useParams();

    useEffect(() => {
        fetchCampaign();
        fetchStatistics();
    }, [campaignId, token]);

    const fetchCampaign = async () => {
        try {
            const response = await fetch(`/api/campaigns/${campaignId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch campaign');
            const data = await response.json();
            setCampaign(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchStatistics = async () => {
        try {
            const response = await fetch(`/api/campaigns/${campaignId}/statistics`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch statistics');
            const data = await response.json();
            setStatistics(data);
        } catch (err) {
            console.error('Error fetching statistics:', err);
        }
    };

    const handleStatusChange = async (action) => {
        try {
            const response = await fetch(`/api/campaigns/${campaignId}/${action}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) throw new Error(`Failed to ${action} campaign`);
            fetchCampaign();
        } catch (err) {
            setError(err.message);
        }
    };

    const handleDelete = async () => {
        try {
            const response = await fetch(`/api/campaigns/${campaignId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to delete campaign');
            // Redirect to campaigns list or handle deletion success
            window.location.href = '/campaigns';
        } catch (err) {
            setError(err.message);
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;
    if (!campaign) return <Alert severity="info">Campaign not found</Alert>;

    return (
        <Box sx={{ p: 3 }}>
            <Paper sx={{ p: 3, mb: 3 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" gutterBottom>
                            {campaign.name}
                        </Typography>
                        <Box sx={{ mb: 2 }}>
                            <Chip
                                label={campaign.status}
                                color={
                                    campaign.status === 'active'
                                        ? 'success'
                                        : campaign.status === 'paused'
                                            ? 'warning'
                                            : campaign.status === 'completed'
                                                ? 'info'
                                                : 'error'
                                }
                                sx={{ mr: 1 }}
                            />
                            {campaign.error && (
                                <Alert severity="error" sx={{ mt: 1 }}>
                                    {campaign.error}
                                </Alert>
                            )}
                        </Box>
                        <Typography variant="body1" paragraph>
                            {campaign.description}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Start Date
                                </Typography>
                                <Typography variant="body1">
                                    {format(new Date(campaign.startDate), 'PPP')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    End Date
                                </Typography>
                                <Typography variant="body1">
                                    {format(new Date(campaign.endDate), 'PPP')}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Posts per Day
                                </Typography>
                                <Typography variant="body1">{campaign.postsPerDay}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Estimated Credits
                                </Typography>
                                <Typography variant="body1">
                                    {campaign.estimatedCredits} credits
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {campaign.status === 'draft' && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleStatusChange('start')}
                                    fullWidth
                                >
                                    Start Campaign
                                </Button>
                            )}
                            {campaign.status === 'active' && (
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() => handleStatusChange('pause')}
                                    fullWidth
                                >
                                    Pause Campaign
                                </Button>
                            )}
                            {campaign.status === 'paused' && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleStatusChange('start')}
                                    fullWidth
                                >
                                    Resume Campaign
                                </Button>
                            )}
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setDeleteDialogOpen(true)}
                                fullWidth
                            >
                                Delete Campaign
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {statistics && <CampaignStatistics statistics={statistics} campaign={campaign} />}

            <Paper sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                    Video Posts
                </Typography>
                <Grid container spacing={2}>
                    {campaign.posts.map((post) => (
                        <Grid item xs={12} sm={6} md={4} key={post._id}>
                            <Paper sx={{ p: 2 }}>
                                <Typography variant="subtitle2" gutterBottom>
                                    Status: {post.status}
                                </Typography>
                                {post.scheduledFor && (
                                    <Typography variant="body2" color="text.secondary">
                                        Scheduled: {format(new Date(post.scheduledFor), 'PPP p')}
                                    </Typography>
                                )}
                                {post.publishedAt && (
                                    <Typography variant="body2" color="text.secondary">
                                        Published: {format(new Date(post.publishedAt), 'PPP p')}
                                    </Typography>
                                )}
                                {post.videoUrl && (
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        href={post.videoUrl}
                                        target="_blank"
                                        sx={{ mt: 1 }}
                                    >
                                        View on TikTok
                                    </Button>
                                )}
                                {post.error && (
                                    <Alert severity="error" sx={{ mt: 1 }}>
                                        {post.error}
                                    </Alert>
                                )}
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

            <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                <DialogTitle>Delete Campaign</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete this campaign? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDelete} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CampaignDetail; 