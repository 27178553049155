import { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    CardActions,
    Button,
    Box,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Chip,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from '@mui/material';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl, getHeaders } from '../config/api';
import LoadingSpinner from '../components/LoadingSpinner';
import PageContainer from '../components/PageContainer';

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

const Billing = () => {
    const [packages, setPackages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [showPaymentDialog, setShowPaymentDialog] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [transactionType, setTransactionType] = useState('all');
    const { user, refreshUser } = useAuth();
    const stripe = useStripe();
    const elements = useElements();
    const [showPaymentMethodDialog, setShowPaymentMethodDialog] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [selectedPackageForPurchase, setSelectedPackageForPurchase] = useState(null);

    useEffect(() => {
        fetchData();
    }, [page, rowsPerPage, transactionType]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token');
            const headers = { Authorization: `Bearer ${token}` };

            // Fetch credit packages
            const packagesResponse = await axios.get(
                getApiUrl('/api/payments/credit-packages'),
                { headers }
            );
            setPackages(packagesResponse.data);

            // Fetch payment methods
            const methodsResponse = await axios.get(
                getApiUrl('/api/payments/payment-methods'),
                { headers }
            );
            setPaymentMethods(methodsResponse.data);

            // Fetch transactions
            const typeQuery = transactionType !== 'all' ? `&type=${transactionType}` : '';
            const transactionsResponse = await axios.get(
                getApiUrl(`/api/payments/transactions?page=${page + 1}&limit=${rowsPerPage}${typeQuery}`),
                { headers }
            );
            setTransactions(transactionsResponse.data.transactions);
            setTotalPages(transactionsResponse.data.totalPages);
        } catch (error) {
            toast.error('Error fetching billing data');
        } finally {
            setLoading(false);
        }
    };

    const handlePurchase = async (packageId) => {
        setSelectedPackageForPurchase(packageId);

        // If user has no payment methods, show the add payment method dialog
        if (!paymentMethods || paymentMethods.length === 0) {
            setShowPaymentDialog(true);
            return;
        }

        // If user has multiple payment methods but no default, show payment method selection dialog
        if (!user.stripeDefaultPaymentMethodId && paymentMethods.length > 1) {
            setShowPaymentMethodDialog(true);
            return;
        }

        // If user has only one payment method or a default payment method, proceed with purchase
        await processPurchase(packageId, user.stripeDefaultPaymentMethodId || paymentMethods[0].id);
    };

    const processPurchase = async (packageId, paymentMethodId) => {
        setLoading(true);
        try {
            const headers = getHeaders();
            const response = await axios.post(
                getApiUrl('/api/payments/purchase-credits'),
                {
                    packageId,
                    paymentMethodId
                },
                { headers }
            );

            console.log('Purchase response:', response.data); // Debug log

            const { clientSecret, paymentIntentId } = response.data;

            if (!clientSecret || !paymentIntentId) {
                throw new Error('Invalid response from server: missing clientSecret or paymentIntentId');
            }

            // Confirm the payment with Stripe using the selected payment method
            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodId,
                return_url: window.location.origin + '/billing'
            });

            if (error) {
                throw error;
            }

            // Start polling for webhook completion
            let attempts = 0;
            const maxAttempts = 20; // 40 seconds total (20 attempts × 2 seconds)
            const pollInterval = 2000; // 2 seconds

            const pollForUpdates = async () => {
                try {
                    // Check payment intent status
                    const statusResponse = await axios.get(
                        getApiUrl(`/api/payments/payment-status/${paymentIntentId}`),
                        { headers }
                    );

                    if (statusResponse.data.status === 'succeeded') {
                        // Payment succeeded, update all necessary data
                        await Promise.all([
                            refreshUser(), // Update user data including credits
                            fetchData()    // Update transactions list
                        ]);
                        toast.success('Credits purchased successfully');
                        return true;
                    }

                    attempts++;
                    if (attempts < maxAttempts) {
                        setTimeout(pollForUpdates, pollInterval);
                    } else {
                        // If polling times out but webhook was successful, try one final refresh
                        await Promise.all([
                            refreshUser(),
                            fetchData()
                        ]);
                        toast.warning('Payment processing may take longer than expected. Your credits will be added soon.');
                    }
                } catch (error) {
                    console.error('Error polling for updates:', error);
                    attempts++;
                    if (attempts < maxAttempts) {
                        setTimeout(pollForUpdates, pollInterval);
                    } else {
                        // If polling times out but webhook was successful, try one final refresh
                        await Promise.all([
                            refreshUser(),
                            fetchData()
                        ]);
                        toast.warning('Payment processing may take longer than expected. Your credits will be added soon.');
                    }
                }
            };

            // Start polling
            pollForUpdates();
        } catch (error) {
            console.error('Payment error:', error);
            toast.error(error.response?.data?.message || 'Error purchasing credits');
        } finally {
            setLoading(false);
            setShowPaymentMethodDialog(false);
            setSelectedPackageForPurchase(null);
        }
    };

    const handlePaymentMethodSelect = async () => {
        if (selectedPaymentMethod && selectedPackageForPurchase) {
            await processPurchase(selectedPackageForPurchase, selectedPaymentMethod);
        }
    };

    const handlePayment = async (paymentMethodId, pkg) => {
        setProcessing(true);
        try {
            const token = localStorage.getItem('token');

            // Create payment intent
            const { data: { clientSecret, customerId } } = await axios.post(
                getApiUrl('/api/payments/create-payment-intent'),
                {
                    amount: pkg.price,
                    credits: pkg.credits
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Confirm payment
            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethodId,
                customer: customerId
            });

            if (error) {
                throw error;
            }

            toast.success(`Successfully purchased ${pkg.credits} credits!`);
            setShowPaymentDialog(false);

            // Start polling for updates
            let attempts = 0;
            const maxAttempts = 10;
            const pollInterval = 2000; // 2 seconds

            const pollForUpdates = async () => {
                try {
                    await refreshUser(); // Update user credits
                    await fetchData(); // Update transactions

                    // Check if the latest transaction is completed
                    const latestTransaction = transactions[0];
                    if (latestTransaction && latestTransaction.status === 'completed') {
                        return true;
                    }

                    attempts++;
                    if (attempts < maxAttempts) {
                        setTimeout(pollForUpdates, pollInterval);
                    }
                } catch (error) {
                    console.error('Error polling for updates:', error);
                    attempts++;
                    if (attempts < maxAttempts) {
                        setTimeout(pollForUpdates, pollInterval);
                    }
                }
            };

            // Start polling
            pollForUpdates();
        } catch (error) {
            toast.error('Error processing payment');
            console.error('Payment error:', error);
        } finally {
            setProcessing(false);
        }
    };

    const handleNewCardPayment = async (event) => {
        event.preventDefault();
        if (!stripe || !elements || !selectedPackage) {
            return;
        }

        setProcessing(true);
        try {
            const token = localStorage.getItem('token');

            // Create payment method
            const { error: methodError, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)
            });

            if (methodError) {
                throw methodError;
            }

            // Save payment method to user's account
            await axios.post(
                getApiUrl('/api/payments/payment-methods'),
                { paymentMethodId: paymentMethod.id },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            // Process payment with new payment method
            await handlePayment(paymentMethod.id, selectedPackage);
        } catch (error) {
            toast.error('Error processing payment');
            console.error('Payment error:', error);
        } finally {
            setProcessing(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'completed':
                return 'success';
            case 'pending':
                return 'warning';
            case 'failed':
                return 'error';
            default:
                return 'default';
        }
    };

    const fetchPaymentMethods = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/payments/payment-methods'),
                { headers }
            );
            setPaymentMethods(response.data.paymentMethods);
        } catch (error) {
            toast.error('Error fetching payment methods');
        }
    };

    const handlePurchaseCredits = async (packageId, paymentMethodId = null) => {
        try {
            setLoading(true);
            const headers = getHeaders();
            const response = await axios.post(
                getApiUrl('/api/payments/purchase-credits'),
                {
                    packageId,
                    paymentMethodId
                },
                { headers }
            );

            if (response.data.requiresAction) {
                const { error } = await stripe.handleCardAction(
                    response.data.clientSecret
                );

                if (error) {
                    toast.error('Payment failed');
                    return;
                }

                // Confirm the payment with the backend
                await axios.post(
                    getApiUrl('/api/payments/confirm-payment'),
                    {
                        paymentIntentId: response.data.paymentIntentId
                    },
                    { headers }
                );
            }

            toast.success('Credits purchased successfully');
            refreshUser();
        } catch (error) {
            toast.error('Error purchasing credits');
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <PageContainer title="Billing">
            <Grid container spacing={3}>
                {/* Credit Packages */}
                <Grid item xs={12} md={8}>
                    <Typography variant="h6" gutterBottom>
                        Credit Packages
                    </Typography>
                    <Grid container spacing={2}>
                        {packages.map((pkg) => (
                            <Grid item xs={12} sm={6} md={4} key={pkg.id}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" component="div" gutterBottom>
                                            {pkg.credits} Credits
                                        </Typography>
                                        <Typography variant="h4" color="primary" gutterBottom>
                                            ${pkg.price}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            ${(pkg.price / pkg.credits).toFixed(2)} per credit
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={() => handlePurchase(pkg.id)}
                                            disabled={processing}
                                        >
                                            {processing ? 'Processing...' : 'Purchase'}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Transaction History */}
                <Grid item xs={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                        Transaction History
                    </Typography>
                    <Box mb={2}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel>Transaction Type</InputLabel>
                            <Select
                                value={transactionType}
                                label="Transaction Type"
                                onChange={(e) => setTransactionType(e.target.value)}
                            >
                                <MenuItem value="all">All Transactions</MenuItem>
                                <MenuItem value="purchase">Purchases</MenuItem>
                                <MenuItem value="usage">Usage</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell align="right">Credits</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {transactions.map((transaction) => (
                                    <TableRow key={transaction._id}>
                                        <TableCell>
                                            {new Date(transaction.createdAt).toLocaleDateString()}
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={transaction.type}
                                                color={transaction.type === 'purchase' ? 'primary' : 'secondary'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>{transaction.description}</TableCell>
                                        <TableCell align="right">
                                            {transaction.type === 'purchase' ? '+' : '-'}
                                            {transaction.credits}
                                        </TableCell>
                                        <TableCell align="right">
                                            {transaction.amount ? `$${transaction.amount.toFixed(2)}` : '-'}
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={transaction.status}
                                                color={getStatusColor(transaction.status)}
                                                size="small"
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            count={totalPages * rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            {/* Payment Method Selection Dialog */}
            <Dialog
                open={showPaymentMethodDialog}
                onClose={() => !processing && setShowPaymentMethodDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Select Payment Method</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        {paymentMethods.map((method) => (
                            <Card
                                key={method.id}
                                sx={{
                                    mb: 2,
                                    cursor: 'pointer',
                                    border: selectedPaymentMethod === method.id ? '2px solid #1976d2' : 'none'
                                }}
                                onClick={() => setSelectedPaymentMethod(method.id)}
                            >
                                <CardContent>
                                    <Typography variant="subtitle1">
                                        {method.card.brand.charAt(0).toUpperCase() + method.card.brand.slice(1)} ending in {method.card.last4}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Expires {method.card.exp_month}/{method.card.exp_year}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowPaymentMethodDialog(false)}
                        disabled={processing}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handlePaymentMethodSelect}
                        disabled={processing || !selectedPaymentMethod}
                    >
                        {processing ? 'Processing...' : 'Pay'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={showPaymentDialog}
                onClose={() => !processing && setShowPaymentDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add Payment Method</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleNewCardPayment} sx={{ mt: 2 }}>
                        <Box sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: 1 }}>
                            <CardElement options={CARD_ELEMENT_OPTIONS} />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowPaymentDialog(false)}
                        disabled={processing}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleNewCardPayment}
                        disabled={processing || !stripe}
                    >
                        {processing ? 'Processing...' : 'Pay'}
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer>
    );
};

export default Billing; 