import { useNavigate } from 'react-router-dom';
import { Box, Typography, Alert, Paper } from '@mui/material';
import { CampaignForm } from '../components/CampaignForm';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl, getHeaders } from '../config/api';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';
import PageContainer from '../components/PageContainer';
import { differenceInDays } from 'date-fns';

export default function CreateCampaign() {
    const navigate = useNavigate();
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [userCredits, setUserCredits] = useState(0);
    const [estimatedCredits, setEstimatedCredits] = useState(0);

    useEffect(() => {
        Promise.all([fetchTemplates(), fetchUserCredits()]).finally(() => {
            setLoading(false);
        });
    }, []);

    const fetchUserCredits = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/auth/me'),
                { headers }
            );
            setUserCredits(response.data.credits);
        } catch (error) {
            toast.error('Error fetching user credits');
        }
    };

    const fetchTemplates = async () => {
        try {
            console.log('Fetching templates...');
            const headers = getHeaders();
            console.log('Headers:', headers);
            const url = getApiUrl('/api/templates');
            console.log('Request URL:', url);

            const response = await axios.get(url, { headers });
            console.log('Response:', response);
            console.log('Response data:', response.data);

            if (!response.data || !response.data.templates) {
                console.error('Invalid response structure:', response.data);
                throw new Error('Invalid response structure from templates API');
            }

            // Transform template URLs to include the correct paths
            const transformedTemplates = response.data.templates.map(template => ({
                ...template,
                videoUrl: template.videoUrl ? `/api/uploads/templates/${template.videoUrl}` : null,
                thumbnailUrl: template.thumbnail ? `/api/uploads/thumbnails/${template.thumbnail}` : null
            }));
            console.log('Transformed templates:', transformedTemplates);
            setTemplates(transformedTemplates);
        } catch (error) {
            console.error('Error fetching templates:', error);
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            toast.error('Error fetching templates');
            setTemplates([]); // Set empty array on error to prevent undefined
        }
    };

    const calculateRequiredCredits = (campaignData) => {
        const startDate = new Date(campaignData.startDate);
        const endDate = new Date(campaignData.endDate);
        const postsPerDay = parseInt(campaignData.postsPerDay);
        const videoTemplates = campaignData.videoTemplates;

        // Calculate number of days
        const days = differenceInDays(endDate, startDate) + 1;

        // Calculate total posts
        const totalPosts = days * postsPerDay;

        // Each post costs 5 credits
        const requiredCredits = totalPosts * 5;

        return requiredCredits;
    };

    const handleSubmit = async (campaignData) => {
        const requiredCredits = calculateRequiredCredits(campaignData);

        if (requiredCredits > userCredits) {
            toast.error(`Insufficient credits. Required: ${requiredCredits}, Available: ${userCredits}`);
            return Promise.reject(new Error('Insufficient credits'));
        }

        try {
            const headers = getHeaders();
            const campaignPayload = {
                ...campaignData,
                estimatedCredits: requiredCredits
            };
            const response = await axios.post(
                getApiUrl('/api/campaigns'),
                campaignPayload,
                { headers }
            );

            if (response.status === 201 || response.status === 200) {
                toast.success('Campaign created successfully');
                navigate('/campaigns');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error creating campaign';
            toast.error(errorMessage);
            throw error; // Re-throw the error to be handled by the form
        }
    };

    const handleCampaignChange = (formData) => {
        if (formData.startDate && formData.endDate && formData.postsPerDay) {
            const days = differenceInDays(formData.endDate, formData.startDate) + 1;
            const totalPosts = days * formData.postsPerDay;
            setEstimatedCredits(totalPosts * 5);
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <PageContainer title="Create Campaign">
            <Box sx={{ mb: 3 }}>
                <Paper sx={{ p: 2, mb: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Available Credits: {userCredits}
                    </Typography>
                    {estimatedCredits > 0 && (
                        <Typography color={estimatedCredits > userCredits ? "error" : "success"}>
                            Estimated Credits Required: {estimatedCredits}
                        </Typography>
                    )}
                </Paper>
                {estimatedCredits > userCredits && (
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        You don't have enough credits for this campaign. Please adjust the duration or posts per day, or purchase more credits.
                    </Alert>
                )}
            </Box>
            <CampaignForm
                onSubmit={handleSubmit}
                templates={templates}
                onChange={handleCampaignChange}
            />
        </PageContainer>
    );
} 