import { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActions,
    Button,
    Box,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Chip,
    FormControlLabel,
    Switch,
    Alert,
    InputAdornment,
    Pagination,
    CardActionArea,
    Stack,
    Tooltip,
    LinearProgress,
    CircularProgress
} from '@mui/material';
import {
    Add as AddIcon,
    Search as SearchIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    CloudUpload as CloudUploadIcon,
    PlayArrow as PlayArrowIcon
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl, getHeaders } from '../config/api';
import LoadingSpinner from '../components/LoadingSpinner';
import PageContainer from '../components/PageContainer';

// Helper function to get filename from path
const getFilename = (filepath) => {
    if (!filepath) return '';
    return filepath.split('/').pop();
};

const Templates = () => {
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [uploadDialog, setUploadDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        tags: '',
        file: null,
        isPublic: false
    });
    const { user } = useAuth();
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    useEffect(() => {
        fetchTemplates();
    }, [page, searchQuery]);

    const fetchTemplates = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl(`/api/templates?page=${page}&search=${searchQuery}`),
                { headers }
            );
            setTemplates(response.data.templates);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            toast.error('Error fetching templates');
        } finally {
            setLoading(false);
        }
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        if (!formData.file) {
            toast.error('Please select a video file');
            return;
        }

        const file = formData.file;
        await handleVideoUpload(file);
    };

    const handleVideoUpload = async (file) => {
        // Check file size (500MB limit)
        if (file.size > 500 * 1024 * 1024) {
            toast.error('Video file must be less than 500MB');
            return;
        }

        // Check file type
        const allowedTypes = ['video/mp4', 'video/quicktime'];
        if (!allowedTypes.includes(file.type)) {
            toast.error('Only MP4 and MOV files are allowed');
            return;
        }

        const formDataToSend = new FormData();
        formDataToSend.append('video', file);
        formDataToSend.append('name', formData.name);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('tags', formData.tags);
        formDataToSend.append('isPublic', formData.isPublic);

        try {
            setUploading(true);
            setUploadProgress(0);
            const headers = getHeaders();
            const response = await axios.post(
                getApiUrl('/api/templates'),
                formDataToSend,
                {
                    headers: {
                        ...headers,
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(percentCompleted);
                    }
                }
            );

            toast.success('Template uploaded successfully');
            setUploadDialog(false);
            setFormData({
                name: '',
                description: '',
                tags: '',
                file: null,
                isPublic: false
            });
            fetchTemplates();
        } catch (error) {
            console.error('Error uploading template:', error);
            if (error.response?.status === 413) {
                toast.error('Video file is too large. Maximum size is 500MB');
            } else {
                toast.error(error.response?.data?.message || 'Error uploading template');
            }
        } finally {
            setUploading(false);
            setUploadProgress(0);
        }
    };

    const handleEdit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const headers = getHeaders();
            await axios.patch(
                getApiUrl(`/api/templates/${selectedTemplate._id}`),
                {
                    name: formData.name,
                    description: formData.description,
                    tags: formData.tags,
                    isPublic: formData.isPublic
                },
                { headers }
            );
            toast.success('Template updated successfully');
            setEditDialog(false);
            fetchTemplates();
        } catch (error) {
            toast.error('Error updating template');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (templateId) => {
        try {
            const headers = getHeaders();
            await axios.delete(
                getApiUrl(`/api/templates/${templateId}`),
                { headers }
            );
            toast.success('Template deleted successfully');
            fetchTemplates();
            setDeleteDialog(false);
        } catch (error) {
            toast.error('Error deleting template');
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 100 * 1024 * 1024) { // 100MB limit
                toast.error('File size must be less than 100MB');
                return;
            }
            setFormData({ ...formData, file });
        }
    };

    // Add upload progress dialog
    const UploadProgressDialog = () => (
        <Dialog open={uploading} maxWidth="sm" fullWidth>
            <DialogTitle>Uploading Template</DialogTitle>
            <DialogContent>
                <Box sx={{ width: '100%', mt: 2 }}>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                        {uploadProgress}% Complete
                    </Typography>
                    <LinearProgress variant="determinate" value={uploadProgress} />
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 2, textAlign: 'center' }}>
                        Please don't close this window while the upload is in progress
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );

    // Update the upload dialog content
    const renderUploadDialog = () => (
        <Dialog open={uploadDialog} onClose={() => !uploading && setUploadDialog(false)} maxWidth="sm" fullWidth>
            <DialogTitle>Upload New Template</DialogTitle>
            <form onSubmit={handleUpload}>
                <DialogContent>
                    <TextField
                        fullWidth
                        label="Template Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        required
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Description"
                        value={formData.description}
                        onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                        multiline
                        rows={3}
                        margin="normal"
                    />
                    <TextField
                        fullWidth
                        label="Tags (comma separated)"
                        value={formData.tags}
                        onChange={(e) => setFormData({ ...formData, tags: e.target.value })}
                        margin="normal"
                        helperText="Enter tags separated by commas"
                    />
                    <Box sx={{ mt: 2 }}>
                        <input
                            accept="video/mp4,video/quicktime"
                            style={{ display: 'none' }}
                            id="video-file"
                            type="file"
                            onChange={handleFileChange}
                            disabled={uploading}
                        />
                        <label htmlFor="video-file">
                            <Button
                                variant="outlined"
                                component="span"
                                startIcon={<CloudUploadIcon />}
                                disabled={uploading}
                                fullWidth
                            >
                                {formData.file ? getFilename(formData.file.name) : 'Choose Video File'}
                            </Button>
                        </label>
                    </Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={formData.isPublic}
                                onChange={(e) => setFormData({ ...formData, isPublic: e.target.checked })}
                                disabled={uploading}
                            />
                        }
                        label="Make this template public"
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => !uploading && setUploadDialog(false)} disabled={uploading}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={!formData.file || uploading}
                        startIcon={uploading ? <CircularProgress size={20} /> : null}
                    >
                        {uploading ? 'Uploading...' : 'Upload'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );

    if (loading && !templates.length) {
        return <LoadingSpinner />;
    }

    return (
        <PageContainer
            title="Video Templates"
            action={
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setUploadDialog(true)}
                >
                    Upload Template
                </Button>
            }
        >
            {/* Add the upload progress dialog */}
            <UploadProgressDialog />

            {/* Add the upload dialog */}
            {renderUploadDialog()}

            <Box mb={4} sx={{ width: '100%' }}>
                <TextField
                    fullWidth
                    placeholder="Search templates..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                    sx={{
                        maxWidth: '400px'
                    }}
                />
            </Box>

            <Grid container spacing={4} sx={{ width: '100%' }}>
                {templates.map((template) => (
                    <Grid item xs={12} sm={6} md={4} key={template._id}>
                        <Card sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            overflow: 'hidden',
                            transition: 'transform 0.2s, box-shadow 0.2s',
                            '&:hover': {
                                transform: 'translateY(-4px)',
                                boxShadow: (theme) => theme.shadows[8]
                            }
                        }}>
                            <Box sx={{ position: 'relative', paddingTop: '177.77%' }}>
                                <CardMedia
                                    component="video"
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover'
                                    }}
                                    src={`/api/uploads/templates/${template.videoUrl}`}
                                    poster={template.thumbnail ? `/api/uploads/thumbnails/${template.thumbnail}` : undefined}
                                    controls
                                />
                            </Box>
                            <CardContent sx={{ flexGrow: 1, p: 3 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={1}>
                                    <Typography variant="h6" component="div" sx={{ fontWeight: 500 }}>
                                        {template.name}
                                    </Typography>
                                    {template.isPublic && (
                                        <Chip
                                            label="Public"
                                            size="small"
                                            color="primary"
                                            sx={{ ml: 1 }}
                                        />
                                    )}
                                </Box>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{
                                        mb: 2,
                                        display: '-webkit-box',
                                        WebkitLineClamp: 2,
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis'
                                    }}
                                >
                                    {template.description}
                                </Typography>
                                <Box display="flex" gap={0.5} flexWrap="wrap">
                                    {template.tags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            size="small"
                                            variant="outlined"
                                            sx={{
                                                bgcolor: 'background.default',
                                                fontSize: '0.75rem'
                                            }}
                                        />
                                    ))}
                                </Box>
                            </CardContent>
                            <CardActions sx={{ p: 2, pt: 0 }}>
                                <Stack direction="row" spacing={1} sx={{ ml: 'auto' }}>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedTemplate(template);
                                                setFormData({
                                                    name: template.name,
                                                    description: template.description,
                                                    tags: template.tags.join(', '),
                                                    isPublic: template.isPublic
                                                });
                                                setEditDialog(true);
                                            }}
                                        >
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => {
                                                setSelectedTemplate(template);
                                                setDeleteDialog(true);
                                            }}
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {templates.length === 0 && !loading && (
                <Alert severity="info" sx={{ mt: 2 }}>
                    No templates found. Upload your first template to get started!
                </Alert>
            )}

            {totalPages > 1 && (
                <Box mt={4} display="flex" justifyContent="center">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        color="primary"
                    />
                </Box>
            )}

            {/* Edit Dialog */}
            <Dialog
                open={editDialog}
                onClose={() => !loading && setEditDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Edit Template</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={handleEdit} sx={{ mt: 2 }}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={formData.name}
                            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            required
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Description"
                            value={formData.description}
                            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                            multiline
                            rows={3}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            fullWidth
                            label="Tags (comma separated)"
                            value={formData.tags}
                            onChange={(e) => setFormData({ ...formData, tags: e.target.value })}
                            sx={{ mb: 2 }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={formData.isPublic}
                                    onChange={(e) => setFormData({ ...formData, isPublic: e.target.checked })}
                                />
                            }
                            label="Make template public"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditDialog(false)}>Cancel</Button>
                    <Button
                        onClick={handleEdit}
                        variant="contained"
                        disabled={loading}
                    >
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Delete Template</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete "{selectedTemplate?.name}"? This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
                    <Button
                        onClick={() => handleDelete(selectedTemplate?._id)}
                        variant="contained"
                        color="error"
                        disabled={loading}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </PageContainer>
    );
};

export default Templates; 