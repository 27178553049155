import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Typography,
    Box,
    Grid,
    Paper,
    Button,
    Card,
    CardContent,
    CardActions,
    LinearProgress,
    Chip,
    Alert
} from '@mui/material';
import {
    Add as AddIcon,
    CreditCard as CreditCardIcon,
    VideoLibrary as VideoLibraryIcon
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { getApiUrl, getHeaders } from '../config/api';
import LoadingSpinner from '../components/LoadingSpinner';
import PageContainer from '../components/PageContainer';

const Dashboard = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [stats, setStats] = useState({
        activeCampaigns: 0,
        totalPosts: 0,
        publishedPosts: 0,
        templates: 0,
        creditsUsed: 0,
        recentTransactions: []
    });
    const [recentCampaigns, setRecentCampaigns] = useState([]);

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/dashboard'),
                { headers }
            );
            setStats(response.data.stats);
            setRecentCampaigns(response.data.recentCampaigns);
        } catch (error) {
            toast.error('Error fetching dashboard data');
        } finally {
            setLoading(false);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'active':
                return 'success';
            case 'paused':
                return 'warning';
            case 'completed':
                return 'info';
            case 'failed':
                return 'error';
            default:
                return 'default';
        }
    };

    const calculateProgress = (campaign) => {
        const total = campaign.posts.length;
        const published = campaign.posts.filter(post => post.status === 'published').length;
        return (published / total) * 100;
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <PageContainer title="Dashboard">
            <Grid container spacing={3}>
                {/* Stats Cards */}
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Active Campaigns
                        </Typography>
                        <Typography variant="h3">
                            {stats.activeCampaigns}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Total Posts
                        </Typography>
                        <Typography variant="h3">
                            {stats.totalPosts}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {stats.publishedPosts} published
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Templates
                        </Typography>
                        <Typography variant="h3">
                            {stats.templates}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Credits Used
                        </Typography>
                        <Typography variant="h3">
                            {stats.creditsUsed}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {user.credits} remaining
                        </Typography>
                    </Paper>
                </Grid>

                {/* Quick Actions */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Quick Actions
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    onClick={() => navigate('/campaigns/create')}
                                >
                                    New Campaign
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<VideoLibraryIcon />}
                                    onClick={() => navigate('/templates')}
                                >
                                    Manage Templates
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    startIcon={<CreditCardIcon />}
                                    onClick={() => navigate('/billing')}
                                >
                                    Buy Credits
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Recent Campaigns */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Recent Campaigns
                        </Typography>
                        <Grid container spacing={3}>
                            {recentCampaigns.map((campaign) => (
                                <Grid item xs={12} md={6} key={campaign._id}>
                                    <Card>
                                        <CardContent>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                                <Typography variant="h6">
                                                    {campaign.name}
                                                </Typography>
                                                <Chip
                                                    label={campaign.status}
                                                    color={getStatusColor(campaign.status)}
                                                    size="small"
                                                />
                                            </Box>
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                Created: {format(new Date(campaign.createdAt), 'MMM d, yyyy')}
                                            </Typography>
                                            <Box mt={2}>
                                                <Typography variant="body2" gutterBottom>
                                                    Progress: {Math.round(calculateProgress(campaign))}%
                                                </Typography>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={calculateProgress(campaign)}
                                                    sx={{ height: 8, borderRadius: 1 }}
                                                />
                                            </Box>
                                        </CardContent>
                                        <CardActions>
                                            <Button
                                                size="small"
                                                onClick={() => navigate('/campaigns')}
                                            >
                                                View Details
                                            </Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                            {recentCampaigns.length === 0 && (
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        No campaigns yet. Create your first campaign to get started!
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Dashboard; 