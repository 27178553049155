// Helper function to get API URL
export const getApiUrl = (path) => {
    // Remove any leading slashes to ensure proper path joining
    const cleanPath = path.startsWith('/') ? path.slice(1) : path;
    // Add the base API URL if it's not already included
    const baseUrl = import.meta.env.REACT_APP_API_URL || '';
    return `${baseUrl}/${cleanPath}`;
};

// Helper function to get headers with auth token
export const getHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
        throw new Error('No authentication token found');
    }
    return {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    };
}; 