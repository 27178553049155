import React, { useState, useEffect } from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Chip,
    CircularProgress,
    Alert,
    Card,
    CardContent,
    CardActions,
    Button,
    IconButton,
    Grid2
} from '@mui/material';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../utils/formatters';
import { useAuth } from '../context/AuthContext';

const CampaignList = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [stats, setStats] = useState({
        activeCampaigns: 0,
        totalPosts: 0,
        publishedPosts: 0,
        templates: 0,
        creditsUsed: 0
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { user, token } = useAuth();


    useEffect(() => {
        fetchCampaigns();
    }, [token]);

    const fetchCampaigns = async () => {
        try {
            const response = await fetch('/api/campaigns', {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            if (!response.ok) throw new Error('Failed to fetch campaigns');
            const data = await response.json();
            setCampaigns(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'active':
                return 'success';
            case 'paused':
                return 'warning';
            case 'completed':
                return 'info';
            case 'failed':
                return 'error';
            default:
                return 'default';
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Alert severity="error">{error}</Alert>;

    return (
        <Box sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h4">Your Campaigns</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/campaigns/new')}
                >
                    Create New Campaign
                </Button>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Grid2 container spacing={3}>
                    {/* Stats Cards */}
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper sx={{ p: 3, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Active Campaigns
                            </Typography>
                            <Typography variant="h3">
                                {stats.activeCampaigns}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper sx={{ p: 3, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Total Posts
                            </Typography>
                            <Typography variant="h3">
                                {stats.totalPosts}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {stats.publishedPosts} published
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper sx={{ p: 3, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Templates
                            </Typography>
                            <Typography variant="h3">
                                {stats.templates}
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper sx={{ p: 3, height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Credits Used
                            </Typography>
                            <Typography variant="h3">
                                {stats.creditsUsed}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {user.credits} remaining
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid2>


            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                {campaigns.map((campaign) => (
                    <Grid item xs={12} md={6} lg={4} key={campaign._id}>
                        <Card
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                cursor: 'pointer',
                                '&:hover': {
                                    boxShadow: 6
                                }
                            }}
                            onClick={() => navigate(`/campaigns/${campaign._id}`)}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                                    <Typography variant="h6" component="div" sx={{ flex: 1, mr: 2 }}>
                                        {campaign.name}
                                    </Typography>
                                    <Chip
                                        label={campaign.status}
                                        color={getStatusColor(campaign.status)}
                                        size="small"
                                    />
                                </Box>

                                <Typography variant="body2" color="text.secondary" paragraph>
                                    {campaign.description}
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="text.secondary">
                                            Start Date
                                        </Typography>
                                        <Typography variant="body2">
                                            {format(new Date(campaign.startDate), 'MMM d, yyyy')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="text.secondary">
                                            End Date
                                        </Typography>
                                        <Typography variant="body2">
                                            {format(new Date(campaign.endDate), 'MMM d, yyyy')}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="text.secondary">
                                            Posts per Day
                                        </Typography>
                                        <Typography variant="body2">
                                            {campaign.postsPerDay}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="caption" color="text.secondary">
                                            Estimated Credits
                                        </Typography>
                                        <Typography variant="body2">
                                            {campaign.estimatedCredits}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {campaign.statistics && (
                                    <Box sx={{ mt: 2 }}>
                                        <Typography variant="caption" color="text.secondary">
                                            Progress
                                        </Typography>
                                        <Typography variant="body2">
                                            {campaign.statistics.publishedPosts} of {campaign.totalPosts} videos published
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            Total Views: {formatNumber(campaign.statistics.totalViews)}
                                        </Typography>
                                    </Box>
                                )}
                            </CardContent>
                            <CardActions>
                                <Button
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        navigate(`/campaigns/${campaign._id}`);
                                    }}
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Box>



            {campaigns.length === 0 && (
                <Paper sx={{ p: 3, textAlign: 'center' }}>
                    <Typography variant="h6" color="text.secondary">
                        No campaigns found. Create your first campaign to get started!
                    </Typography>
                </Paper>
            )}
        </Box>
    );
};

export default CampaignList; 