import { Box, Container, Typography } from '@mui/material';

const PageContainer = ({ children, title, action }) => {
    return (
        <Box sx={{
            width: '100%',
            p: { xs: 2, sm: 3 },
            mb: 3,
            mx: 'auto'
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 4
            }}>
                <Typography variant="h5" component="h1" fontWeight="500">
                    {title}
                </Typography>
                {action}
            </Box>
            {children}
        </Box>
    );
};

export default PageContainer; 