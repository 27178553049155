import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl, getHeaders } from '../config/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        console.log('AuthProvider mounted');
        checkAuth();
    }, []);

    const transformUserData = (userData) => {
        if (!userData) return null;
        return {
            ...userData,
            avatar: userData.avatar ? getApiUrl(userData.avatar) : null
        };
    };

    const checkAuth = async () => {
        console.log('Checking auth...');
        const storedToken = localStorage.getItem('token');
        console.log('Token from localStorage:', storedToken ? storedToken.substring(0, 20) + '...' : 'undefined');
        setToken(storedToken);

        if (!storedToken) {
            console.log('No token found, redirecting to login');
            setLoading(false);
            if (!location.pathname.startsWith('/login') &&
                !location.pathname.startsWith('/register') &&
                !location.pathname.startsWith('/forgot-password')) {
                navigate('/login');
            }
            return;
        }

        try {
            console.log('Making /api/auth/me request...');
            const headers = getHeaders();
            console.log('Auth headers:', headers);
            const response = await axios.get(
                getApiUrl('/api/auth/me'),
                { headers }
            );
            console.log('Auth check response:', response.data);
            setUser(transformUserData(response.data));

            // If we're on the login page but we're already authenticated,
            // redirect to dashboard
            if (location.pathname === '/login') {
                navigate('/');
            }
        } catch (error) {
            console.error('Auth check failed:', error);
            console.error('Error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            localStorage.removeItem('token');
            setToken(null);
            if (!location.pathname.startsWith('/login')) {
                navigate('/login');
            }
        } finally {
            setLoading(false);
        }
    };

    const login = async (email, password) => {
        try {
            console.log('Attempting login...');
            const response = await axios.post(
                getApiUrl('/api/auth/login'),
                { email, password }
            );

            const { token: newToken, user: userData } = response.data;
            console.log('Login successful, setting token');
            localStorage.setItem('token', newToken);
            setToken(newToken);
            setUser(transformUserData(userData));

            // Redirect to the page they were trying to access, or dashboard
            const intendedPath = location.state?.from || '/';
            navigate(intendedPath);

            toast.success('Successfully logged in!');
        } catch (error) {
            console.error('Login failed:', error);
            const message = error.response?.data?.message || 'Invalid credentials';
            toast.error(message);
            throw error;
        }
    };

    const register = async (userData) => {
        try {
            const response = await axios.post(
                getApiUrl('/api/auth/register'),
                userData
            );

            const { token, user: newUser } = response.data;
            localStorage.setItem('token', token);
            setUser(transformUserData(newUser));

            navigate('/');
            toast.success('Registration successful!');
        } catch (error) {
            console.error('Registration failed:', error);
            const message = error.response?.data?.message || 'Registration failed';
            toast.error(message);
            throw error;
        }
    };

    const logout = () => {
        console.log('Logging out...');
        localStorage.removeItem('token');
        setToken(null);
        setUser(null);
        navigate('/login');
        toast.info('Logged out successfully');
    };

    const refreshUser = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/auth/me'),
                { headers }
            );
            setUser(transformUserData(response.data));
            return transformUserData(response.data);
        } catch (error) {
            console.error('Error refreshing user data:', error);
            toast.error('Error refreshing user data');
            throw error;
        }
    };

    const forgotPassword = async (email) => {
        try {
            await axios.post(
                getApiUrl('/api/auth/forgot-password'),
                { email }
            );
            toast.success('Password reset email sent');
            return { success: true };
        } catch (error) {
            console.error('Forgot password failed:', error);
            const message = error.response?.data?.message || 'Failed to send reset email';
            toast.error(message);
            return { success: false, error: message };
        }
    };

    const resetPassword = async (token, password) => {
        try {
            await axios.post(
                getApiUrl('/api/auth/reset-password'),
                { token, password }
            );
            toast.success('Password reset successful');
            navigate('/login');
            return { success: true };
        } catch (error) {
            console.error('Password reset failed:', error);
            const message = error.response?.data?.message || 'Failed to reset password';
            toast.error(message);
            return { success: false, error: message };
        }
    };

    const value = {
        user,
        loading,
        token,
        login,
        register,
        logout,
        refreshUser,
        forgotPassword,
        resetPassword,
        checkAuth
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export default AuthContext; 