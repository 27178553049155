import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Box,
    CssBaseline,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
    Button,
    Divider,
    Avatar,
    Menu,
    MenuItem
} from '@mui/material';
import {
    Menu as MenuIcon,
    Dashboard as DashboardIcon,
    Campaign as CampaignIcon,
    Settings as SettingsIcon,
    CreditCard as CreditCardIcon,
    Logout as LogoutIcon,
    VideoLibrary as VideoLibraryIcon,
    ChevronLeft as ChevronLeftIcon,
    Analytics as AnalyticsIcon,
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import { getApiUrl } from '../config/api';

const drawerWidth = 240;




const Layout = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { user, logout } = useAuth();
    const navigate = useNavigate();



    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        handleMenuClose();
        logout();
    };

    const menuItems = [
        { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
        { text: 'Campaigns', icon: <CampaignIcon />, path: '/campaigns' },
        { text: 'Templates', icon: <VideoLibraryIcon />, path: '/templates' },
        { text: 'Analytics', icon: <AnalyticsIcon />, path: '/analytics' },
    ];
    const topMenuItems = [
        { text: 'Settings', icon: <DashboardIcon />, function: () => navigate('/settings') },
        { text: 'Billing', icon: <CreditCardIcon />, function: () => navigate('/billing') },
        { text: 'Logout', icon: <LogoutIcon />, function: () => handleLogout() },
    ];

    const drawer = (
        <Box>
            <Toolbar>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src="/ArtistViralLogoTransparent-120x120.png"
                        alt="ArtistViral Logo"
                        style={{ height: '100px', width: 'auto', paddingTop: '10px', paddingBottom: '10px' }}
                    />
                    <h2>ArtistViral</h2>
                </Box>
            </Toolbar>
            <Divider />
            <List>
                {menuItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton onClick={() => navigate(item.path)}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                <ListItem>
                    <Box sx={{ width: '100%', p: 1 }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            Credit Balance
                        </Typography>
                        <Typography variant="h6" color="primary">
                            {user?.credits || 0} credits
                        </Typography>
                        <Button
                            variant="outlined"
                            size="small"
                            startIcon={<CreditCardIcon />}
                            onClick={() => navigate('/billing')}
                            sx={{ mt: 1 }}
                            fullWidth
                        >
                            Buy Credits
                        </Button>
                    </Box>
                </ListItem>
            </List>
        </Box>
    );

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    height: { xs: 56, sm: 64 }
                }}
            >
                <Toolbar sx={{ minHeight: { xs: 56, sm: 64 } }}>
                    <IconButton
                        color="inherit"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        onClick={handleMenuClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={Boolean(anchorEl) ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                    >
                        <Avatar
                            src={user?.avatar ? getApiUrl("/api" + user.avatar) : undefined}
                            sx={{ width: 32, height: 32 }}
                        >
                            {user?.firstName?.charAt(0) || user?.email?.charAt(0)}
                        </Avatar>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            >
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        }
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth
                        }
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    mt: { xs: '56px', sm: '64px' },
                    height: 'calc(100vh - 64px)',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Outlet />
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                onClick={handleMenuClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {topMenuItems.map((item) => (
                    <MenuItem style={{ 'padding': '26px' }} key={item.text} onClick={item.function}>
                        {item.icon}&nbsp;&nbsp;&nbsp;{item.text}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default Layout; 