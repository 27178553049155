import React from 'react';
import {
    Box,
    Paper,
    Typography,
    Grid,
    LinearProgress,
    Tooltip
} from '@mui/material';
import { formatNumber } from '../utils/formatters';

const CampaignStatistics = ({ statistics, campaign }) => {
    if (!statistics) return null;

    const progress = statistics.totalPosts > 0
        ? (statistics.publishedPosts / statistics.totalPosts) * 100
        : 0;
    const engagementRate = statistics.totalViews > 0
        ? ((statistics.totalLikes + statistics.totalComments + statistics.totalShares) / statistics.totalViews) * 100
        : 0;

    return (
        <Paper sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
                Campaign Progress
            </Typography>
            <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                        {statistics.publishedPosts} of {campaign.totalPosts} videos published
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {Math.round(progress)}%
                    </Typography>
                </Box>
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    sx={{ height: 8, borderRadius: 4 }}
                />
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary">
                            {formatNumber(statistics.totalViews)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Total Views
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary">
                            {formatNumber(statistics.totalLikes)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Total Likes
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary">
                            {formatNumber(statistics.totalComments)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Total Comments
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 2, textAlign: 'center' }}>
                        <Typography variant="h6" color="primary">
                            {formatNumber(statistics.totalShares)}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Total Shares
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>

            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Tooltip title="Engagement Rate = (Likes + Comments + Shares) / Total Views">
                    <Typography variant="h6" color="primary">
                        {engagementRate.toFixed(2)}% Engagement Rate
                    </Typography>
                </Tooltip>
            </Box>
        </Paper>
    );
};

export default CampaignStatistics; 