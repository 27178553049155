import { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Grid,
    Paper,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Button,
    Alert,
    ButtonGroup,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    Line,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import { format, subDays, subMonths } from 'date-fns';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl, getHeaders } from '../config/api';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LoadingSpinner from '../components/LoadingSpinner';
import PageContainer from '../components/PageContainer';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const Analytics = () => {
    const [loading, setLoading] = useState(true);
    const [analytics, setAnalytics] = useState({
        campaigns: [],
        aggregateMetrics: {
            totalViews: 0,
            totalLikes: 0,
            totalComments: 0,
            totalShares: 0,
            totalPosts: 0,
            totalCreditsUsed: 0,
            overallEngagementRate: 0
        }
    });
    const [dateRange, setDateRange] = useState({
        startDate: subMonths(new Date(), 1),
        endDate: new Date()
    });
    const [exportFormat, setExportFormat] = useState('csv');

    useEffect(() => {
        fetchAnalytics();
    }, [dateRange]);

    const fetchAnalytics = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/analytics/user'),
                {
                    headers,
                    params: {
                        startDate: dateRange.startDate.toISOString(),
                        endDate: dateRange.endDate.toISOString()
                    }
                }
            );
            setAnalytics(response.data);
        } catch (error) {
            toast.error('Error fetching analytics');
        } finally {
            setLoading(false);
        }
    };

    const handleExport = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl(`/api/analytics/export`),
                {
                    headers,
                    params: {
                        startDate: dateRange.startDate.toISOString(),
                        endDate: dateRange.endDate.toISOString(),
                        format: exportFormat
                    },
                    responseType: 'blob'
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `analytics_${format(new Date(), 'yyyy-MM-dd')}.${exportFormat}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            toast.error('Error exporting analytics');
        }
    };

    const handleQuickDateRange = (days) => {
        setDateRange({
            startDate: subDays(new Date(), days),
            endDate: new Date()
        });
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <PageContainer title="Analytics">
            <Box mb={4}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item>
                        <ButtonGroup variant="outlined" size="small">
                            <Button onClick={() => handleQuickDateRange(7)}>7d</Button>
                            <Button onClick={() => handleQuickDateRange(30)}>30d</Button>
                            <Button onClick={() => handleQuickDateRange(90)}>90d</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <Box display="flex" gap={2}>
                            <DatePicker
                                label="Start Date"
                                value={dateRange.startDate}
                                onChange={(date) => setDateRange({ ...dateRange, startDate: date })}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                            <DatePicker
                                label="End Date"
                                value={dateRange.endDate}
                                onChange={(date) => setDateRange({ ...dateRange, endDate: date })}
                                slotProps={{ textField: { size: 'small' } }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <Box display="flex" gap={2}>
                            <FormControl size="small">
                                <InputLabel>Export Format</InputLabel>
                                <Select
                                    value={exportFormat}
                                    onChange={(e) => setExportFormat(e.target.value)}
                                    label="Export Format"
                                >
                                    <MenuItem value="csv">CSV</MenuItem>
                                    <MenuItem value="xlsx">Excel</MenuItem>
                                </Select>
                            </FormControl>
                            <Button
                                variant="outlined"
                                startIcon={<FileDownloadIcon />}
                                onClick={handleExport}
                            >
                                Export
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={3}>
                {/* Overview Cards */}
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Total Views
                        </Typography>
                        <Typography variant="h3">
                            {analytics.aggregateMetrics.totalViews.toLocaleString()}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Total Engagement
                        </Typography>
                        <Typography variant="h3">
                            {(
                                analytics.aggregateMetrics.totalLikes +
                                analytics.aggregateMetrics.totalComments +
                                analytics.aggregateMetrics.totalShares
                            ).toLocaleString()}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {analytics.aggregateMetrics.overallEngagementRate.toFixed(2)}% engagement rate
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Total Posts
                        </Typography>
                        <Typography variant="h3">
                            {analytics.aggregateMetrics.totalPosts.toLocaleString()}
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Paper sx={{ p: 3, height: '100%' }}>
                        <Typography variant="h6" gutterBottom>
                            Credits Used
                        </Typography>
                        <Typography variant="h3">
                            {analytics.aggregateMetrics.totalCreditsUsed.toLocaleString()}
                        </Typography>
                    </Paper>
                </Grid>

                {/* Charts */}
                <Grid item xs={12} md={8}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Engagement Over Time
                        </Typography>
                        <Box height={400}>
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    data={analytics.campaigns}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="createdAt"
                                        tickFormatter={(timestamp) => format(new Date(timestamp), 'MMM d')}
                                    />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="metrics.views"
                                        name="Views"
                                        stroke="#8884d8"
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="metrics.likes"
                                        name="Likes"
                                        stroke="#82ca9d"
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="metrics.shares"
                                        name="Shares"
                                        stroke="#ffc658"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Engagement Distribution
                        </Typography>
                        <Box height={400}>
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={[
                                            { name: 'Likes', value: analytics.aggregateMetrics.totalLikes },
                                            { name: 'Comments', value: analytics.aggregateMetrics.totalComments },
                                            { name: 'Shares', value: analytics.aggregateMetrics.totalShares }
                                        ]}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={false}
                                        label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {analytics.campaigns.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                        </Box>
                    </Paper>
                </Grid>

                {/* Campaign Performance Table */}
                <Grid item xs={12}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Campaign Performance
                        </Typography>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Campaign</TableCell>
                                        <TableCell align="right">Views</TableCell>
                                        <TableCell align="right">Likes</TableCell>
                                        <TableCell align="right">Comments</TableCell>
                                        <TableCell align="right">Shares</TableCell>
                                        <TableCell align="right">Engagement Rate</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {analytics.campaigns.map((campaign) => (
                                        <TableRow key={campaign._id}>
                                            <TableCell>{campaign.name}</TableCell>
                                            <TableCell align="right">
                                                {campaign.metrics.views.toLocaleString()}
                                            </TableCell>
                                            <TableCell align="right">
                                                {campaign.metrics.likes.toLocaleString()}
                                            </TableCell>
                                            <TableCell align="right">
                                                {campaign.metrics.comments.toLocaleString()}
                                            </TableCell>
                                            <TableCell align="right">
                                                {campaign.metrics.shares.toLocaleString()}
                                            </TableCell>
                                            <TableCell align="right">
                                                {campaign.metrics.engagementRate.toFixed(2)}%
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Analytics;