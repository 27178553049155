import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    InputLabel,
    TextField,
    Typography,
    LinearProgress,
    Stack,
    Paper,
    Grid,
    IconButton,
    Card,
    CardContent,
    CardMedia,
    Select,
    MenuItem,
    Chip,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { toast } from 'react-toastify';
import { format, isBefore, startOfDay } from 'date-fns';
import axios from 'axios';
import { getApiUrl, getHeaders } from '../config/api';

export const CampaignForm = ({ onSubmit, templates, onChange }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        videoTemplates: [],
        startDate: null,
        endDate: null,
        postsPerDay: 1,
    });
    const [audioFile, setAudioFile] = useState(null);
    const [audioUploadProgress, setAudioUploadProgress] = useState(0);
    const [selectedTemplates, setSelectedTemplates] = useState([]);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (onChange) {
            onChange(formData);
        }
    }, [formData, onChange]);

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setAudioFile(acceptedFiles[0]);
            setErrors({ ...errors, audioFile: null });
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'audio/*': ['.mp3', '.wav', '.m4a']
        },
        maxSize: 10 * 1024 * 1024, // 10MB
        onDropRejected: (fileRejections) => {
            const error = fileRejections[0]?.errors[0]?.message || 'Invalid file';
            setErrors({ ...errors, audioFile: error });
        }
    });

    const handleTemplateSelect = (template) => {
        const isSelected = selectedTemplates.find(t => t._id === template._id);

        if (isSelected) {
            setSelectedTemplates(prev => prev.filter(t => t._id !== template._id));
            setFormData(prev => ({
                ...prev,
                videoTemplates: prev.videoTemplates.filter(id => id !== template._id)
            }));
        } else {
            setSelectedTemplates(prev => [...prev, template]);
            setFormData(prev => ({
                ...prev,
                videoTemplates: [...prev.videoTemplates, template._id]
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newValue = name === 'postsPerDay' ? parseInt(value) : value;
        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }));
        setErrors(prev => ({ ...prev, [name]: null }));
    };

    const handleDateChange = (date, field) => {
        setFormData(prev => ({
            ...prev,
            [field]: date
        }));
        setErrors(prev => ({ ...prev, [field]: null }));
    };

    const handleTemplateChange = (event) => {
        const selectedValues = Array.isArray(event.target.value) ? event.target.value : [];
        setFormData(prev => ({
            ...prev,
            videoTemplates: selectedValues
        }));
        setErrors(prev => ({ ...prev, videoTemplates: null }));
    };

    const validateForm = () => {
        const newErrors = {};
        const today = startOfDay(new Date());

        if (!formData.name.trim()) newErrors.name = 'Name is required';
        if (!formData.description.trim()) newErrors.description = 'Description is required';
        if (!formData.videoTemplates.length) newErrors.videoTemplates = 'At least one template is required';
        if (!formData.startDate) {
            newErrors.startDate = 'Start date is required';
        } else if (isBefore(formData.startDate, today)) {
            newErrors.startDate = 'Start date cannot be before today';
        }
        if (!formData.endDate) {
            newErrors.endDate = 'End date is required';
        } else if (formData.startDate && isBefore(formData.endDate, formData.startDate)) {
            newErrors.endDate = 'End date must be after start date';
        }
        if (!audioFile) newErrors.audioFile = 'Audio file is required';
        if (formData.postsPerDay < 1) newErrors.postsPerDay = 'Posts per day must be at least 1';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsLoading(true);
        try {
            // First, upload the audio file
            const audioFormData = new FormData();
            audioFormData.append('audio', audioFile);

            const headers = getHeaders();
            const audioResponse = await axios.post(
                getApiUrl('/api/campaigns/upload-audio'),
                audioFormData,
                {
                    headers: {
                        ...headers,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            // Now create the campaign with the processed audio file data
            const campaignData = {
                name: formData.name,
                description: formData.description,
                videoTemplates: formData.videoTemplates,
                startDate: format(formData.startDate, 'yyyy-MM-dd'),
                endDate: format(formData.endDate, 'yyyy-MM-dd'),
                postsPerDay: formData.postsPerDay,
                audioFile: audioResponse.data
            };

            await onSubmit(campaignData);
        } catch (error) {
            // Don't show another error message since it's already handled in the parent
            return;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Container component="form" onSubmit={handleSubmit} maxWidth="md">
            <Stack spacing={3}>
                <TextField
                    required
                    fullWidth
                    label="Campaign Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                />

                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    label="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    error={!!errors.description}
                    helperText={errors.description}
                />

                <FormControl required error={!!errors.videoTemplates}>
                    <InputLabel shrink>Video Templates</InputLabel>
                    <Box sx={{ mt: 2 }}>
                        <Grid container spacing={2}>
                            {Array.isArray(templates) && templates.map((template) => (
                                <Grid item xs={12} sm={6} md={4} key={template._id}>
                                    <Card
                                        sx={{
                                            cursor: 'pointer',
                                            border: selectedTemplates.find(t => t._id === template._id) ? 2 : 1,
                                            borderColor: selectedTemplates.find(t => t._id === template._id) ? 'primary.main' : 'grey.300'
                                        }}
                                        onClick={() => handleTemplateSelect(template)}
                                    >
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={template.thumbnailUrl}
                                            alt={template.name}
                                        />
                                        <CardContent>
                                            <Typography variant="body1">{template.name}</Typography>
                                            {template.duration && (
                                                <Typography variant="body2" color="text.secondary">
                                                    Duration: {Math.round(template.duration)}s
                                                </Typography>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {errors.videoTemplates && (
                        <FormHelperText error>{errors.videoTemplates}</FormHelperText>
                    )}
                </FormControl>

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            label="Start Date"
                            value={formData.startDate}
                            onChange={(date) => handleDateChange(date, 'startDate')}
                            minDate={startOfDay(new Date())}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!errors.startDate,
                                    helperText: errors.startDate
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <DatePicker
                            label="End Date"
                            value={formData.endDate}
                            onChange={(date) => handleDateChange(date, 'endDate')}
                            minDate={formData.startDate || startOfDay(new Date())}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !!errors.endDate,
                                    helperText: errors.endDate
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <TextField
                    fullWidth
                    type="number"
                    label="Posts Per Day"
                    name="postsPerDay"
                    value={formData.postsPerDay}
                    onChange={handleChange}
                    inputProps={{ min: 1, max: 10 }}
                    error={!!errors.postsPerDay}
                    helperText={errors.postsPerDay}
                />

                <FormControl required>
                    <InputLabel shrink>Audio File</InputLabel>
                    <Paper
                        {...getRootProps()}
                        sx={{
                            p: 3,
                            textAlign: 'center',
                            cursor: 'pointer',
                            bgcolor: isDragActive ? 'action.hover' : 'background.paper',
                            border: theme => `2px dashed ${errors.audioFile ? theme.palette.error.main : theme.palette.divider}`
                        }}
                    >
                        <input {...getInputProps()} />
                        <Typography>
                            {audioFile
                                ? `Selected: ${audioFile.name}`
                                : isDragActive
                                    ? 'Drop the audio file here'
                                    : 'Drag and drop an audio file here, or click to select'}
                        </Typography>
                        {errors.audioFile && (
                            <Typography color="error" variant="caption" display="block">
                                {errors.audioFile}
                            </Typography>
                        )}
                    </Paper>
                    {audioUploadProgress > 0 && (
                        <LinearProgress variant="determinate" value={audioUploadProgress} sx={{ mt: 1 }} />
                    )}
                </FormControl>

                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    disabled={isLoading}
                >
                    {isLoading ? 'Creating Campaign...' : 'Create Campaign'}
                </Button>
            </Stack>
        </Container>
    );
}; 