import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Paper,
    Typography,
    Button,
    Box,
    Grid,
    Card,
    CardContent,
    CardActions,
    Divider,
    Alert,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Switch,
    FormControlLabel,
    FormGroup,
    IconButton,
    Avatar,
    Stack,
    Chip
} from '@mui/material';
import {
    Check as CheckIcon,
    Link as LinkIcon,
    CreditCard as CreditCardIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
    PhotoCamera as PhotoCameraIcon
} from '@mui/icons-material';
import { useAuth } from '../context/AuthContext';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import StripeProvider from '../components/StripeProvider';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getApiUrl, getHeaders } from '../config/api';
import LoadingSpinner from '../components/LoadingSpinner';
import PageContainer from '../components/PageContainer';

// Card Element styling
const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: '#32325d',
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#aab7c4'
            }
        },
        invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
        }
    }
};

// Payment Method Card Component
const PaymentMethodCard = ({ method, onDelete, onSetDefault, isDefault }) => {
    const card = method.card;
    const last4 = card.last4;
    const brand = card.brand;
    const expMonth = card.exp_month;
    const expYear = card.exp_year;

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CreditCardIcon sx={{ mr: 1 }} />
                    <Typography variant="h6">
                        {brand.charAt(0).toUpperCase() + brand.slice(1)} ending in {last4}
                    </Typography>
                    {isDefault && (
                        <Chip
                            label="Default"
                            color="primary"
                            size="small"
                            sx={{ ml: 1 }}
                        />
                    )}
                </Box>
                <Typography variant="body2" color="text.secondary">
                    Expires {expMonth}/{expYear}
                </Typography>
            </CardContent>
            <CardActions>
                {!isDefault && (
                    <Button
                        size="small"
                        onClick={() => onSetDefault(method.id)}
                        startIcon={<CheckIcon />}
                    >
                        Set as Default
                    </Button>
                )}
                <Button
                    size="small"
                    color="error"
                    onClick={() => onDelete(method.id)}
                    startIcon={<DeleteIcon />}
                >
                    Remove
                </Button>
            </CardActions>
        </Card>
    );
};

// Update Payment Form Component
const UpdatePaymentForm = ({ onSuccess, onCancel, processing, setProcessing }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        setProcessing(true);
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)
            });

            if (error) {
                toast.error(error.message);
                return;
            }

            const headers = getHeaders();
            await axios.post(
                getApiUrl('/api/payments/payment-methods/update'),
                {
                    paymentMethodId: selectedPaymentMethod.id,
                    newPaymentMethodId: paymentMethod.id
                },
                { headers }
            );

            toast.success('Payment method updated successfully');
            onSuccess();
        } catch (error) {
            toast.error('Error updating payment method');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button onClick={onCancel} disabled={processing}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!stripe || processing}
                >
                    {processing ? 'Updating...' : 'Update Payment Method'}
                </Button>
            </Box>
        </form>
    );
};

// Payment Form Component
const PaymentForm = ({ onSuccess, onCancel, processing, setProcessing }) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useAuth();

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setProcessing(true);
        try {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement)
            });

            if (error) {
                toast.error(error.message);
                return;
            }

            const headers = getHeaders();
            await axios.post(
                getApiUrl('/api/payments/payment-methods'),
                { paymentMethodId: paymentMethod.id },
                { headers }
            );

            toast.success('Payment method added successfully');
            onSuccess();
        } catch (error) {
            console.error('Error adding payment method:', error);
            toast.error('Error adding payment method');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button onClick={onCancel} disabled={processing}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!stripe || processing}
                >
                    {processing ? 'Adding...' : 'Add Payment Method'}
                </Button>
            </Box>
        </form>
    );
};

// Credit Purchase Button Component
const CreditPurchaseButton = ({ packageId, price, credits, loading, onSuccess }) => {
    const stripe = useStripe();
    const [processing, setProcessing] = useState(false);
    const { user } = useAuth();

    const handlePurchase = async () => {
        if (!stripe) return;

        setProcessing(true);
        try {
            const headers = getHeaders();
            const response = await axios.post(
                getApiUrl('/api/payments/purchase-credits'),
                { packageId },
                { headers }
            );

            const { clientSecret } = response.data;

            const { error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: user.stripeDefaultPaymentMethodId
            });

            if (error) {
                throw error;
            }

            toast.success('Credits purchased successfully');
            onSuccess();
        } catch (error) {
            console.error('Payment error:', error);
            toast.error(error.response?.data?.message || 'Error purchasing credits');
        } finally {
            setProcessing(false);
        }
    };

    return (
        <Button
            fullWidth
            variant="contained"
            disabled={!stripe || processing || loading}
            onClick={handlePurchase}
        >
            {processing || loading ? 'Processing...' : 'Purchase'}
        </Button>
    );
};

const Settings = () => {
    const [loading, setLoading] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [showAddCard, setShowAddCard] = useState(false);
    const [creditPackages, setCreditPackages] = useState([]);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState('');
    const [editProfile, setEditProfile] = useState(false);
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        avatar: null
    });
    const [notifications, setNotifications] = useState({
        emailNotifications: true,
        campaignAlerts: true,
        creditAlerts: true,
        marketingEmails: false
    });
    const { user, logout, refreshUser } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const [showUpdateCard, setShowUpdateCard] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        fetchPaymentMethods();
        fetchCreditPackages();
        if (user) {
            setProfileData({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                avatar: user.avatar ? `${import.meta.env.VITE_API_URL}/api${user.avatar}` : null

            });
            setNotifications({
                emailNotifications: user.notifications?.emailNotifications ?? true,
                campaignAlerts: user.notifications?.campaignAlerts ?? true,
                creditAlerts: user.notifications?.creditAlerts ?? true,
                marketingEmails: user.notifications?.marketingEmails ?? false
            });
        }
    }, [user]);

    useEffect(() => {
        // Handle TikTok auth callback
        const searchParams = new URLSearchParams(location.search);
        const tiktokAuth = searchParams.get('tiktok_auth');
        const username = searchParams.get('username');

        if (tiktokAuth === 'success' && username) {
            refreshUser(); // Refresh user data to get updated TikTok status
            toast.success(`Successfully connected TikTok account: @${username}`);
            // Clear the URL parameters
            navigate('/settings', { replace: true });
        } else if (tiktokAuth === 'error') {
            const error = searchParams.get('error');
            toast.error(`Failed to connect TikTok account: ${error || 'Unknown error'}`);
            navigate('/settings', { replace: true });
        }
    }, [location, navigate, refreshUser]);

    const fetchCreditPackages = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/payments/credit-packages'),
                { headers }
            );
            setCreditPackages(response.data || []);
        } catch (error) {
            console.error('Error fetching credit packages:', error);
            toast.error('Error fetching credit packages');
        }
    };

    const fetchPaymentMethods = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/payments/payment-methods'),
                { headers }
            );
            setPaymentMethods(response.data || []);
        } catch (error) {
            console.error('Error fetching payment methods:', error);
            toast.error('Error fetching payment methods');
            setPaymentMethods([]);
        }
    };

    const handleConnectTikTok = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(
                getApiUrl('/api/tiktok/auth-url'),
                { headers }
            );
            window.location.href = response.data.url;
        } catch (error) {
            toast.error('Error connecting to TikTok');
        }
    };

    const handleDisconnectTikTok = async () => {
        if (!window.confirm('Are you sure you want to disconnect your TikTok account?')) {
            return;
        }

        try {
            const headers = getHeaders();
            await axios.post(
                getApiUrl('/api/tiktok/disconnect'),
                {},
                { headers }
            );
            await refreshUser();
            toast.success('TikTok account disconnected successfully');
        } catch (error) {
            console.error('Error disconnecting TikTok:', error);
            toast.error('Error disconnecting TikTok account');
        }
    };

    const handleDeletePaymentMethod = async (paymentMethodId) => {
        if (!window.confirm('Are you sure you want to remove this payment method?')) {
            return;
        }

        try {
            const headers = getHeaders();
            await axios.delete(
                getApiUrl(`/api/payments/payment-methods/${paymentMethodId}`),
                { headers }
            );
            toast.success('Payment method deleted');
            fetchPaymentMethods();
        } catch (error) {
            toast.error('Error deleting payment method');
        }
    };

    const handlePaymentSuccess = () => {
        setShowAddCard(false);
        fetchPaymentMethods();
    };

    const handleProfileUpdate = async () => {
        setLoading(true);
        try {
            const headers = getHeaders();
            const formData = new FormData();
            formData.append('firstName', profileData.firstName);
            formData.append('lastName', profileData.lastName);
            formData.append('email', profileData.email);
            console.log(profileData.newAvatar);
            if (profileData.newAvatar) {
                if (profileData.newAvatar.size > 10 * 1024 * 1024) {
                    throw new Error('Avatar image must be less than 10MB');
                }
                formData.append('avatar', profileData.newAvatar);
            }

            const response = await axios.put(
                getApiUrl('/api/users/profile'),
                formData,
                {
                    headers: {
                        ...headers,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            console.log(response.data);
            // Refresh the user data in context
            const updatedUser = await refreshUser();

            // Update local profile data with the full URL for the avatar
            setProfileData(prev => ({
                ...prev,
                firstName: updatedUser.firstName,
                lastName: updatedUser.lastName,
                email: updatedUser.email,
                avatar: user.avatar ? `${import.meta.env.VITE_API_URL}/api${updatedUser.avatar}?t=${new Date().getTime()}` : null,
                newAvatar: null
            }));

            toast.success('Profile updated successfully');
            setEditProfile(false);
        } catch (error) {
            console.error('Error updating profile:', error);
            toast.error(error.response?.data?.message || 'Error updating profile');
        } finally {
            setLoading(false);
        }
    };

    const handleNotificationUpdate = async (setting) => {
        const newSettings = { ...notifications, [setting]: !notifications[setting] };
        setNotifications(newSettings);

        try {
            const headers = getHeaders();
            await axios.put(
                getApiUrl('/api/users/notifications'),
                { notifications: newSettings },
                { headers }
            );
        } catch (error) {
            toast.error('Error updating notification settings');
            setNotifications(notifications); // Revert on error
        }
    };

    const handleDeleteAccount = async () => {
        if (deleteConfirmation !== user.email) {
            toast.error('Email confirmation does not match');
            return;
        }

        setLoading(true);
        try {
            const headers = getHeaders();
            await axios.delete(
                getApiUrl('/api/users/account'),
                { headers }
            );

            toast.success('Account deleted successfully');
            logout();
            navigate('/register');
        } catch (error) {
            toast.error('Error deleting account');
        } finally {
            setLoading(false);
            setShowDeleteAccount(false);
        }
    };

    const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) { // 10MB limit
                toast.error('Avatar image must be less than 10MB');
                return;
            }
            const tempUrl = URL.createObjectURL(file);
            setProfileData(prev => ({
                ...prev,
                newAvatar: file,
                avatar: tempUrl
            }));
        }
    };

    const fetchUserData = async () => {
        try {
            const headers = getHeaders();
            const response = await axios.get(getApiUrl('/api/auth/me'), { headers });
            // Update user context with new data
            if (response.data.user) {
                await refreshUser(response.data.user);
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleSetDefaultPaymentMethod = async (paymentMethodId) => {
        try {
            const headers = getHeaders();
            await axios.post(
                getApiUrl('/api/payments/payment-methods/default'),
                { paymentMethodId },
                { headers }
            );
            // Refresh user data to get the updated default payment method
            await refreshUser();
            // Refresh payment methods list
            await fetchPaymentMethods();
            toast.success('Default payment method updated');
        } catch (error) {
            console.error('Error setting default payment method:', error);
            toast.error('Error setting default payment method');
        }
    };

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <PageContainer title="Settings">
            <Grid container spacing={3}>
                {/* Profile Section */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Profile
                        </Typography>
                        <Box sx={{ mb: 3 }}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <Box position="relative">
                                    <Avatar
                                        src={profileData.avatar}
                                        sx={{ width: 100, height: 100, mr: 2 }}
                                    >
                                        {profileData.firstName?.charAt(0)}
                                    </Avatar>
                                </Box>
                                <Box>
                                    <Typography variant="h6">
                                        {profileData.firstName} {profileData.lastName}
                                    </Typography>
                                    <Typography color="text.secondary">
                                        {profileData.email}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Button
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={() => setEditProfile(true)}
                        >
                            Edit Profile
                        </Button>
                    </Paper>
                </Grid>

                {/* Notification Preferences */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Notification Preferences
                        </Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={notifications.emailNotifications}
                                        onChange={() => handleNotificationUpdate('emailNotifications')}
                                    />
                                }
                                label="Email Notifications"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={notifications.campaignAlerts}
                                        onChange={() => handleNotificationUpdate('campaignAlerts')}
                                    />
                                }
                                label="Campaign Status Alerts"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={notifications.creditAlerts}
                                        onChange={() => handleNotificationUpdate('creditAlerts')}
                                    />
                                }
                                label="Low Credit Alerts"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={notifications.marketingEmails}
                                        onChange={() => handleNotificationUpdate('marketingEmails')}
                                    />
                                }
                                label="Marketing Emails"
                            />
                        </FormGroup>
                    </Paper>
                </Grid>

                {/* TikTok Connection */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            TikTok Connection
                        </Typography>
                        <Box sx={{ mt: 2 }}>
                            {user?.tiktok ? (
                                <Box>
                                    <Stack direction="row" spacing={2} alignItems="center" sx={{ mb: 2 }}>
                                        <Avatar src={user.tiktok.avatarUrl} alt={user.tiktok.username}>
                                            {user.tiktok.username?.[0]?.toUpperCase()}
                                        </Avatar>
                                        <Box>
                                            <Typography variant="subtitle1">
                                                Connected as @{user.tiktok.username}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Last connected: {new Date(user.tiktok.connectedAt).toLocaleDateString()}
                                            </Typography>
                                        </Box>
                                    </Stack>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={handleDisconnectTikTok}
                                    >
                                        Disconnect TikTok
                                    </Button>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                                        Connect your TikTok account to enable video uploads and analytics.
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        startIcon={<LinkIcon />}
                                        onClick={handleConnectTikTok}
                                    >
                                        Connect TikTok
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Grid>

                {/* Payment Methods */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                            <Typography variant="h6">Payment Methods</Typography>
                            <Button
                                variant="contained"
                                startIcon={<CreditCardIcon />}
                                onClick={() => setShowAddCard(true)}
                            >
                                Add Payment Method
                            </Button>
                        </Box>

                        {paymentMethods.length > 0 ? (
                            <>
                                {paymentMethods.map((method) => (
                                    <PaymentMethodCard
                                        key={method.id}
                                        method={method}
                                        onDelete={handleDeletePaymentMethod}
                                        onSetDefault={handleSetDefaultPaymentMethod}
                                        isDefault={method.id === user.stripeDefaultPaymentMethodId}
                                    />
                                ))}
                                <Button
                                    variant="outlined"
                                    startIcon={<EditIcon />}
                                    onClick={() => {
                                        setSelectedPaymentMethod(paymentMethods[0]);
                                        setShowUpdateCard(true);
                                    }}
                                    sx={{ mt: 2 }}
                                >
                                    Update Payment Method
                                </Button>
                            </>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No payment methods added yet. Click the button above to add one.
                            </Typography>
                        )}
                    </Paper>
                </Grid>

                {/* Credit Packages */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" gutterBottom>
                            Credit Packages
                        </Typography>
                        <Grid container spacing={3}>
                            {creditPackages.map((pkg) => (
                                <Grid item xs={12} sm={6} md={4} key={pkg.id}>
                                    <Card>
                                        <CardContent>
                                            <Typography variant="h6" gutterBottom>
                                                {pkg.name}
                                            </Typography>
                                            <Typography variant="h4" color="primary" gutterBottom>
                                                ${pkg.price}
                                            </Typography>
                                            <Typography>
                                                {pkg.credits} Credits
                                            </Typography>
                                            {pkg.description && (
                                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                    {pkg.description}
                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardActions>
                                            <StripeProvider>
                                                <CreditPurchaseButton
                                                    packageId={pkg.id}
                                                    price={pkg.price}
                                                    credits={pkg.credits}
                                                    loading={loading}
                                                    onSuccess={fetchUserData}
                                                />
                                            </StripeProvider>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Paper>
                </Grid>

                {/* Account Deletion */}
                <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, mb: 3 }}>
                        <Typography variant="h6" gutterBottom color="error">
                            Delete Account
                        </Typography>
                        <Typography variant="body2" color="text.secondary" paragraph>
                            Once you delete your account, there is no going back. Please be certain.
                        </Typography>
                        <Button
                            variant="outlined"
                            color="error"
                            startIcon={<DeleteIcon />}
                            onClick={() => setShowDeleteAccount(true)}
                        >
                            Delete Account
                        </Button>
                    </Paper>
                </Grid>
            </Grid>

            {/* Profile Edit Dialog */}
            <Dialog
                open={editProfile}
                onClose={() => setEditProfile(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Edit Profile</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ mt: 2 }}>
                        <Box display="flex" justifyContent="center" mb={3}>
                            <Box position="relative">
                                <Avatar
                                    src={profileData.avatar}
                                    sx={{ width: 120, height: 120 }}
                                >
                                    {profileData.firstName?.charAt(0)}
                                </Avatar>
                                <IconButton
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        backgroundColor: 'background.paper',
                                        boxShadow: 1,
                                        '&:hover': {
                                            backgroundColor: 'background.paper'
                                        }
                                    }}
                                    component="label"
                                    size="small"
                                >
                                    <input
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        onChange={handleAvatarChange}
                                    />
                                    <PhotoCameraIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        </Box>
                        <TextField
                            fullWidth
                            label="First Name"
                            value={profileData.firstName}
                            onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Last Name"
                            value={profileData.lastName}
                            onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
                            margin="normal"
                            required
                        />
                        <TextField
                            fullWidth
                            label="Email"
                            value={profileData.email}
                            onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                            margin="normal"
                            required
                            type="email"
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditProfile(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={handleProfileUpdate}
                        disabled={loading}
                    >
                        Save Changes
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Delete Account Dialog */}
            <Dialog
                open={showDeleteAccount}
                onClose={() => setShowDeleteAccount(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Delete Account</DialogTitle>
                <DialogContent>
                    <Alert severity="warning" sx={{ mb: 2 }}>
                        This action cannot be undone. All your data will be permanently deleted.
                    </Alert>
                    <Typography paragraph>
                        Please type your email <strong>{user?.email}</strong> to confirm deletion:
                    </Typography>
                    <TextField
                        fullWidth
                        value={deleteConfirmation}
                        onChange={(e) => setDeleteConfirmation(e.target.value)}
                        placeholder="Enter your email"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteAccount(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={handleDeleteAccount}
                        disabled={deleteConfirmation !== user?.email || loading}
                    >
                        Delete My Account
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add Payment Method Dialog */}
            <Dialog
                open={showAddCard}
                onClose={() => !processing && setShowAddCard(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Add Payment Method</DialogTitle>
                <DialogContent>
                    <StripeProvider>
                        <PaymentForm
                            onSuccess={async () => {
                                setShowAddCard(false);
                                await fetchPaymentMethods(); // Ensure we wait for the fetch to complete
                            }}
                            onCancel={() => setShowAddCard(false)}
                            processing={processing}
                            setProcessing={setProcessing}
                        />
                    </StripeProvider>
                </DialogContent>
            </Dialog>

            {/* Update Payment Method Dialog */}
            <Dialog
                open={showUpdateCard}
                onClose={() => !processing && setShowUpdateCard(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Update Payment Method</DialogTitle>
                <DialogContent>
                    <StripeProvider>
                        <UpdatePaymentForm
                            onSuccess={() => {
                                setShowUpdateCard(false);
                                fetchPaymentMethods();
                            }}
                            onCancel={() => setShowUpdateCard(false)}
                            processing={processing}
                            setProcessing={setProcessing}
                        />
                    </StripeProvider>
                </DialogContent>
            </Dialog>
        </PageContainer>
    );
};

export default Settings; 