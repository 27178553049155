import { Box, CircularProgress } from '@mui/material';

const LoadingSpinner = ({ fullHeight = false }) => {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={fullHeight ? '100vh' : '80vh'}
            width="100%"
        >
            <CircularProgress />
        </Box>
    );
};

export default LoadingSpinner; 